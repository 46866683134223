<template>
	<div :class="{'poll': 1, 'loading': loading}" v-if="loading || question !== ''">
		<div class="poll-content">
			<template v-if="question">
				<template v-if="answered">
					<h3>{{ question }}</h3>
					<div class="result-form">
						<div class="result" v-for="answer in answers">
							<div class="percent"><strong>{{ answer.percent }}</strong>%</div>
							<div class="text">
								<strong>{{ answer.answer }}</strong>
								<div class="bar" :style="'width:' + answer.percent + '%'"></div>
							</div>
						</div>
					</div>
				</template>
				<template v-else>
					<h3>{{ question }}</h3>
					<div class="answer-form">
						<button type="button"  v-for="answer in answers" class="btn" @click="saveAnswer(answer)">
							{{ answer.answer }}
						</button>
					</div>
				</template>
			</template>
		</div>
	</div>
</template>
<script>

export default {
	props: {
		id: Number,
		dataUrl: String
	},
	mounted() {
		this.load()
	},
	computed: {
	},
	data(){
		return {
			loading: true,
			question: '',
			answers: [],
			answered: false
		}
	},
	methods: {
		load: function () {
			this.loading = true
			let that = this
			axios.get(this.dataUrl)
					.then(function (response) {
						that.loading = false
						that.question = response.data.question
						that.answers = response.data.answers
						that.answered = response.data.answered > 0
					})
					.catch(function (error) {
					});
		},
		saveAnswer: function (answer){
			this.loading = true
			let that = this
			axios.post(this.dataUrl, {answer: answer.id})
					.then(function (response) {
						that.loading = false
						that.question = response.data.question
						that.answers = response.data.answers
						that.answered = response.data.answered > 0
					})
					.catch(function (error) {
					});

		}
	},
}
</script>

<style scoped>
</style>
