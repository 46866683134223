<template>
	<div>
		<template v-if="showFirstOnly">
			<GalleryImage
				:file="componentImages[0]"
				:post-title="postTitle"
				view="1col"
				:display-details="true"
				:image-count="componentImages.length"
				@open-lightbox="openLightbox(0)"
				:display-image-count="true"
			/>
		</template>
		<template v-else-if="!view || view === '1col'">
			<template v-for="(image, imageIndex) in componentImages">
				<GalleryImage
						:file="image"
						:post-title="postTitle"
						:view="view"
						:display-details="true"
						:display-image-count="false"
						:image-count="componentImages.length"
						v-if="!visibleImages.length || visibleImages.indexOf(imageIndex) > -1"
						@open-lightbox="openLightbox(imageIndex)"
				/>
			</template>
		</template>
		<template v-else>
			<div class="row">
				<template v-for="(image, imageIndex) in componentImages">
					<div
							:class="colClass"
							v-if="!visibleImages.length || visibleImages.indexOf(imageIndex) > -1"
					>
						<GalleryImage
								:file="image"
								:post-title="postTitle"
								:view="view"
								:display-image-count="false"
								:display-details="false"
								:image-count="componentImages.length"
								@open-lightbox="openLightbox(imageIndex)"
						/>
					</div>
				</template>
			</div>
		</template>
	</div>
	<template v-if="lightboxOpen">
		<teleport to="body">
			<div class="lightbox-wrapper">
				<div class="lightbox-content">
					<div class="lightbox-header">
						<div class="lightbox-logo" v-html="logo"></div>
						<a href="#" class="hidden-sm hidden-xs" v-if="!fullscreen" @click.prevent="enterFullScreen"><i class="fas fa-arrows-alt"></i></a>
						<a href="#" class="hidden-sm hidden-xs" v-if="fullscreen" @click.prevent="exitFullScreen"><i class="fas fa-compress"></i></a>
						<a href="#" @click.prevent="closeLightbox"><i class="fas fa-times"></i></a>
					</div>
					<div :class="{'lightbox-body': 1, 'sidebar-hidden': sidebarHidden}">
						<div class="lightbox-sidebar">
							<div class="lightbox-sidebar-actions">
								<a class="lightbox-sidebar-action" title="Oldalsáv elrejtése/mutatása" href="#" @click.prevent="toggleSidebar"><i class="fas fa-info"></i></a>
								<a v-if="slides[lightboxIndex].type === 'image'" :href="slides[lightboxIndex].image.download_url" class="lightbox-sidebar-action" title="Kép letöltése"
									 target="_blank" :download="slides[lightboxIndex].image.name"><i class="fas fa-download"></i></a>
							</div>
							<div class="lightbox-title">{{ postTitle }}</div>
							<div class="lightbox-date">
								<div v-if="slides[lightboxIndex].type === 'image' && slides[lightboxIndex].image.photographer">{{ slides[lightboxIndex].image.photographer }}</div>
								<div v-if="slides[lightboxIndex].type === 'image' && slides[lightboxIndex].image.description">{{ slides[lightboxIndex].image.description }}</div>
								{{ postDate }}
							</div>
							<div class="lightbox-image-count">{{ componentImages.length }} db fotó</div>
							<div class="lightbox-navigation">
								<div class="lightbox-navigation-slider">
									<Flicking :options="{defaultIndex: 0, virtual: false, noPanelStyleOverride: true, useFractionalSize: true, preventClickOnDrag: true, preventDefaultOnDrag: true}" ref="navigationSlider" @ready="navigationSlideReady">
										<div v-for="(navigationImagesPage, navigationImagesPageIndex) in navigationImages" class="lightbox-navigation-slide flicking-panel" :key="'navigation_' + navigationImagesPageIndex">
											<div v-for="(navigationImage, navigationImageIndex) in navigationImagesPage">
												<a
														href="#"
														:class="{'active': (slides[lightboxIndex].imageIndex === navigationImagesPageIndex * 9 + navigationImageIndex) ||
														(slides[lightboxIndex].type !== 'image' && slides[lightboxIndex - 1] !== undefined && slides[lightboxIndex - 1].type === 'image' && slides[lightboxIndex - 1].imageIndex === navigationImagesPageIndex * 9 + navigationImageIndex) ||
														(slides[lightboxIndex].type !== 'image' && slides[lightboxIndex + 1] !== undefined && slides[lightboxIndex + 1].type === 'image' && slides[lightboxIndex + 1].imageIndex === navigationImagesPageIndex * 9 + navigationImageIndex)}"
														:title="navigationImage.image.description ? navigationImage.image.description : postTitle"
														@click.prevent="sliderMove(navigationImage.index)">
													<img v-lazy="{ src: getThumbnailUrl(navigationImage.image, 200, 200), loading: '/pics/loader.svg' }" :alt="navigationImage.image.description ? navigationImage.image.description : postTitle" class="img-fluid" />
												</a>
											</div>
										</div>
									</Flicking>
								</div>
								<div class="lightbox-navigation-buttons">
									<a href="#" @click.prevent="navigationSliderMove(-1)" :class="{'disabled': navigationSliderIndex === 0}"><i class="fa fa-angle-left"></i></a>
									<a href="#" @click.prevent="navigationSliderMove(1)" :class="{'disabled': navigationSliderIndex >= navigationImages.length - 1}"><i class="fa fa-angle-right"></i></a>
								</div>
							</div>
						</div>
						<div :class="{'lightbox-viewport': 1, 'd-none': !sliderLoaded}" @click.prevent="closeLightbox">
							<carousel :items-to-show="1" :wrap-around="false" v-model="lightboxIndex" ref="lightboxSlider" aria-label="Cikkek" @init="slideReady" @slide-start="slideStart">
								<slide v-for="(slide, slideIndex) in slides" :class="'lightbox-slide'" :key="'slide_' + slideIndex">
									<template v-if="slide.type === 'image'">
										<GalleryLazyLoadImage
											:src="getUrl(slide.image)"
											:alt="slide.image.description ? slide.image.description : postTitle"
											:ref="'image_' + slideIndex"
											/>
									</template>
									<template v-if="slide.type === 'banner'">
										<div class="lightbox-banner" v-html="slide.banner"></div>
									</template>
									<template v-if="slide.type === 'recommended'">
										<div class="recommended-posts">
											<h3>Vége! Nézd meg ezeket is!</h3>
											<div class="recommended-posts-list">
												<div class="row">
													<div class="col-sm-6" v-for="post in slide.recommended">
														<article>
															<div class="img">
																<span class="icon-over-image"><i class="fas fa-clone"></i> Galéria</span>
																<img v-lazy="{ src: post.thumbnail, loading: '/pics/loader.svg' }" class="img-fluid" :alt="post.title">
															</div>
															<div class="details">
																<h2>{{ post.title }}</h2>
																{{ post.image_count }} db fotó
																<div v-if="post.photographer">
																	{{ post.photographer }}
																</div>
															</div>
															<a :href="post.url"></a>
														</article>
													</div>
												</div>
												<button type="button" class="btn btn-primary btn-block reload"><span>Kérek <i class="fa fa-redo-alt"></i> újat</span></button>
											</div>
										</div>
									</template>
								</slide>
							</carousel>
							<a href="#" :class="{'lightbox-viewport-prev': 1, 'disabled': !lightboxIndex}" @click.prevent.stop="sliderMoveRelative(-1)"><i class="fa fa-angle-double-left"></i></a>
							<a href="#" :class="{'lightbox-viewport-next': 1, 'disabled': lightboxIndex >= slides.length - 1}" @click.prevent.stop="sliderMoveRelative(1)"><i class="fa fa-angle-double-right"></i></a>
						</div>
					</div>
				</div>
			</div>
		</teleport>
	</template>
</template>
<script>
import GalleryImage from "./GalleryImage.vue";
import Flicking from "@egjs/vue3-flicking";
import PinchScrollZoom from '@coddicat/vue-pinch-scroll-zoom';
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import GalleryLazyLoadImage from "./GalleryLazyLoadImage.vue";

export default {
	components: {GalleryLazyLoadImage, GalleryImage, Flicking, PinchScrollZoom, Carousel, Slide, Pagination, Navigation},
	props: {
		images: Array,
		showFirstOnly: Boolean,
		view: String,
		logo: String,
		postTitle: String,
		postDate: String,
		id: Number,
		postId: Number,
		singleImageSections: Array
	},
	computed: {
		colClass: {
			get() {
				if (this.view === '2col') {
					return 'col-12 col-lg-6'
				}
				if (this.view === '3col') {
					return 'col-12 col-lg-6 col-xl-4'
				}
				if (this.view === '4col') {
					return 'col-6 col-lg-4 col-xl-3'
				}

				return 'col'
			}
		},
		navigationImages: {
			get() {
				let payload = [[]]
				let index = 0
				_.forEach(this.slides, function (image, slideIndex){
					if (image.type !== 'image'){
						return
					}
					if (payload[index].length >= 9){
						index++
						payload.push([])
					}
					payload[index].push(image)
				})

				return payload
			}
		}
	},
	mounted() {
		let that = this

		document.addEventListener("keydown", function (e){
			if (e.key === 'Escape'){
				that.closeLightbox()
			}
			if (e.key === 'ArrowLeft'){
				that.sliderMoveRelative(-1)
			}
			if (e.key === 'ArrowRight'){
				that.sliderMoveRelative(1)
			}
			if (e.key === 'f'){
				if (that.fullscreen){
					that.exitFullScreen()
				} else {
					that.enterFullScreen()
				}
			}
		}, false);
	},
	data() {
		let componentImages = this.images
		let visibleImages = []
		if (componentImages.length === 1 && this.singleImageSections.length > 1){
			let that = this
			componentImages = []

			_.forEach(this.singleImageSections, function (image, imageIndex){
				componentImages.push(image.image)
				if (image.id === that.id){
					visibleImages.push(imageIndex)
				}
			})
		}

		return {
			lightboxOpen: false,
			lightboxIndex: 0,
			fullscreen: false,
			navigationSliderIndex: 0,
			sidebarHidden: false,
			sliderLoaded: false,
			banners: [],
			slides: [],
			visibleImages: visibleImages,
			componentImages: componentImages,
			loadedImages: [],
			updateCarouselCounter: 0,
			updateCarouselInterval: null
		}
	},
	methods: {
		openLightbox: function (index) {
			let banners = document.getElementById('galleryBanners_' + this.id);
			if (banners){
				this.banners = JSON.parse(banners.dataset.banners)
			}

            this.sidebarHidden = document.body.clientWidth < 992

			if (!this.slides.length){
				let that = this
				let bannerPositions = []

				if (this.componentImages.length > 3){
					if (this.componentImages.length < 15){
						bannerPositions.push(Math.round(this.componentImages.length / 2))
					} else {
						bannerPositions.push(Math.round(this.componentImages.length / 3))
						bannerPositions.push(Math.round(this.componentImages.length / 3) * 2)
					}
				}


				_.forEach(this.componentImages, function (image, index){
					that.slides.push({
						type: 'image',
						image: image,
						imageIndex: index,
						index: that.slides.length
					})
					if (bannerPositions.indexOf(index) > -1 && that.banners.length){
						that.slides.push({
							type: 'banner',
							banner: that.banners.pop(),
							index: that.slides.length
						})
					}
				})

				if (this.slides.length > 1){
					if (document.getElementById('recommendedPosts_' + this.postId)) {
						let recommended = JSON.parse(document.getElementById('recommendedPosts_' + this.postId).dataset.posts)
						if (recommended.length) {
							that.slides.push({
								type: 'recommended',
								recommended: recommended,
								index: that.slides.length
							})
						}
					}
				}
			}

			this.lightboxIndex = index
			this.lightboxOpen = true

			document.getElementsByTagName('html')[0].classList.add('overlay-open')
		},
		closeLightbox: function () {
			if (this.fullscreen){
				this.exitFullScreen()
			}
			this.lightboxOpen = false

			document.getElementsByTagName('html')[0].classList.remove('overlay-open')
		},
		enterFullScreen: function () {
			if (document.documentElement.requestFullscreen) {
				document.documentElement.requestFullscreen();
			} else if (document.documentElement.mozRequestFullScreen) {
				document.documentElement.mozRequestFullScreen();
			} else if (document.documentElement.webkitRequestFullscreen) {
				document.documentElement.webkitRequestFullscreen();
			} else if (document.documentElement.msRequestFullscreen) {
				document.documentElement.msRequestFullscreen();
			}
			this.fullscreen = true
			this.hideSidebar()
		},
		exitFullScreen: function () {
			if (document.exitFullscreen) {
				document.exitFullscreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitExitFullscreen) {
				document.webkitExitFullscreen();
			}
			this.fullscreen = false
			this.showSidebar()
		},
		getThumbnailUrl: function (file, width, height) {
			return '/files/' + file.hash.substring(0, 2) + '/' + file.hash.substring(2, 4) + '/' + file.hash + '_' + width + 'x' + height + '_c.' + file.extension
		},
		getUrl: function (file) {
			return '/files/' + file.hash.substring(0, 2) + '/' + file.hash.substring(2, 4) + '/' + file.hash + '.' + file.extension
		},
		navigationSlideReady: function (e){
			this.$refs.navigationSlider.moveTo(0, 0)
		},
		slideReady: function (e){
			this.sliderLoaded = true

			this.$refs['image_' + this.lightboxIndex][0].loading = true
			this.sliderMove(this.lightboxIndex)
		},
		navigationSliderMove: function(move){
			if (move > 0){
				if (this.navigationSliderIndex < this.navigationImages.length -1){
					this.navigationSliderIndex++
					this.$refs.navigationSlider.next()
				}
			} else {
				if (this.navigationSliderIndex){
					this.navigationSliderIndex--
					this.$refs.navigationSlider.prev()
				}
			}
		},
		sliderMove: function (index){
			this.lightboxIndex = index
			let that = this
			setTimeout(function (){
				if (that.slides[that.lightboxIndex].type === 'image') {
					let page = Math.floor(that.slides[that.lightboxIndex].imageIndex / 9)
					that.$refs.navigationSlider.moveTo(page)
				}
			}, 50)
		},
		sliderMoveRelative: function (direction){
			if (direction > 0 && this.lightboxIndex < this.slides.length -1){
				this.lightboxIndex++
			}
			if (direction < 0 && this.lightboxIndex){
				this.lightboxIndex--
			}

			this.sliderMove(this.lightboxIndex)
		},
		slideChanged: function (e){
			let elements = this.$refs.lightboxSlider.panels[e.index].element.getElementsByClassName('lightbox-banner')
			if (elements.length){
				let element = elements[0]

				if (element.dataset.inited === undefined){
					element.dataset.inited = 1
					let scripts = element.getElementsByTagName("script");
					while (scripts.length) {
						let script = scripts[0];
						script.parentNode.removeChild(script);
						let newScript = document.createElement("script");
						if (script.src) {
							newScript.src = script.src;
						} else if (script.textContent) {
							newScript.textContent = script.textContent;
						} else if (script.innerText) {
							newScript.innerText = script.innerText;
						}
						document.body.appendChild(newScript);
					}
				}
			}
		},
		slideStart: function (details){
			this.$refs['image_' + details.slidingToIndex][0].loading = true
            if (this.$refs['image_' + (details.slidingToIndex + 1)] !== undefined) {
                this.$refs['image_' + (details.slidingToIndex + 1)][0].loading = true
            }
		},
		toggleSidebar: function (){
			this.sidebarHidden = !this.sidebarHidden

			let that = this
			this.updateCarouselCounter = 0
			if (this.updateCarouselInterval === null) {
				this.updateCarouselInterval = setInterval(function () {
                    if (that.$refs.lightboxSlider !== undefined) {
                        that.$refs.lightboxSlider.updateSlideWidth()
                    }
					if (that.updateCarouselCounter > 50){
						clearInterval(that.updateCarouselInterval)
						that.updateCarouselInterval = null
					}
				}, 10)
			}

		}
	},
}
</script>

<style scoped>
</style>
