<template>
	<div :class="{'post-search-field--wrapper': 1, 'post-search-field--focus': (showResults && focus) || focus}">
		<div class="post-search-field--input-wrapper">
            <div class="post-search-field--input">
                <input type="text" v-model="query" :placeholder="!query.length && previousQuery.length ? previousQuery : 'Keresés'" @keydown="onKeydown" @input="onInput" @focus="onFocus" @blur="onBlur" />
                <i :class="{'far': 1, 'fa-search': !loading, 'fa-spinner': loading, 'fa-spin': loading}"></i>
            </div>
		</div>
		<div class="post-search-field--dropdown" v-if="showResults && focus">
			<div class="post-search-field--no-results" v-if="!results.length">
				Nincs találat :(
			</div>
			<template v-else>
				<div class="post-search-field--result" v-for="result in results">
					<div class="date">{{ result.date }}</div>
					<h3>{{ result.title }}</h3>
					<a :href="result.url"></a>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
export default {
	data(){
		return {
			query: '',
			previousQuery: '',
			timer: null,
			loading: false,
			results: [],
			showResults: false,
			focus: false
		}
	},
	methods: {
		search() {
			const url = `/search/${encodeURI(this.query)}`
			this.previousQuery = this.query

			axios.get(`/search/${encodeURI(this.query)}`).then((response)=>{
				this.loading = false
				this.results = response.data.results
				this.showResults = true
			}).catch(function (error) {
			})
		},
		onFocus(e) {
			if (this.query.length || this.previousQuery.length){
				this.showResults = true
			}
			this.focus = true
		},
		onBlur(e) {
			let that = this
			setTimeout(function(){
				that.focus = false
			}, 300)

		},
		onInput(e) {
			if (this.query !== this.previousQuery){
				if (this.timer !== null){
					clearTimeout(this.timer)
					this.timer = null
				}

				let that = this
				this.timer = setTimeout(function(){
					that.search()
				}, 200)
			}
		},
        onKeydown(e) {
            if (e.key === 'Enter' && this.query.length){
                location.href = '/kereses?query=' + this.query
            }
		},
	},
}
</script>

<style scoped>

</style>
