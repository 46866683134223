<template>
	<VueCountdown :time="countdownTime" v-slot="{ days, hours, minutes, seconds }" v-if="countdownTime">
		<template v-if="days">
			{{ days}} nap {{ hours }} óra {{ minutes }} perc {{ seconds}} mp
		</template>
		<template v-else>
			{{ hours }} óra {{ minutes }} perc {{ seconds}} mp
		</template>
	</VueCountdown>
</template>

<script>
import VueCountdown from "@chenfengyuan/vue-countdown";
export default {
	components: [VueCountdown],
	props: {
		endDate: String
	},
	data() {
		return {
			countdownTime: Math.max(0, new Date(this.endDate).getTime() - new Date().getTime())
		}
	}
}
</script>


<style scoped>

</style>