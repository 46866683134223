<template>
	<div :class="{'game': 1, 'loading': loading}">
		<div class="game-content" v-if="game.title !== undefined">
			<template v-if="played">
				<div class="alert alert-success">Sikeres játék!</div>
			</template>
			<template v-else>
				<template v-if="!game.started">
					<div class="alert alert-danger">A játék még nem kezdődött el, kezdés {{ formatDate(game.start_at) }}</div>
				</template>
				<template v-else-if="!game.ended">
					<div class="questions-container">
						<div class="question" v-for="question in questions">
							<div class="question-text mb-3">
								<strong>{{ question.index }}</strong>
								{{ question.question }}
							</div>
							<div class="mb-3" v-if="question.image !== null">
								<img :src="question.image" :alt="question.question" class="img-fluid" />
							</div>
							<div class="answer-form">
								<template v-for="answer in question.answers">
									<button type="button"  :class="{'btn': 1, 'selected': answers[question.id] === answer.id}" @click="answers[question.id] = answer.id; updateCanSubmitForm()">
										{{ answer.answer }}
									</button>
								</template>
							</div>
						</div>
					</div>
					<form-wrapper
						ref="formWrapper"
						v-model="player"
						:initial-flash="[]"
						v-slot="{ errors }"
						:submit-function="saveAnswers"
						:show-buttons="false">
						<div class="row">
							<div class="col-sm-6">
								<input-field
									name="name"
									label="Neved"
									rules="required"
									:errors="errors"
									v-model="player.name"
									@input="updateCanSubmitForm"
									/>
							</div>
							<div class="col-sm-6">
								<input-field
									name="email"
									label="E-mail címed"
									data-type="email"
									rules="required"
									:errors="errors"
									v-model="player.email"
									@input="updateCanSubmitForm"
								/>
							</div>
							<div class="col-sm-6">
								<input-field
									name="city"
									label="Városod"
									rules="required"
									:errors="errors"
									v-model="player.city"
									@input="updateCanSubmitForm"
								/>
							</div>
						</div>
                        <div class="mb-3">
                            <div id="recaptcha_container"></div>
                        </div>
						<div class="checkbox mb-3" v-if="rulesUrl">
							<div class="form-check">
								<input class="form-check-input" type="checkbox" :id="'gameRulesCheckbox_' + id" @change="updateCanSubmitForm" v-model="player.acceptRules">
								<label class="form-check-label" :for="'gameRulesCheckbox_' + id">
									Elolvastam és elfogadom a <a :href="rulesUrl" target="_blank">játékszabályzatot</a>
								</label>
							</div>
						</div>
						<div v-if="message.length" class="alert alert-danger mb-3">
							{{ message }}
						</div>
						<div class="d-flex justify-content-center">
                            {{ errors }}
							<button type="submit" :disabled="Object.keys(errors).length > 0 || !player.acceptRules || player.token === ''" class="btn btn-primary">Beküldés</button>
						</div>
					</form-wrapper>
				</template>
				<template v-else>
					<div class="questions-container">
						<div class="question" v-for="question in questions">
							<div class="question-text mb-3">
								<strong>{{ question.index }}</strong>
								{{ question.question }}
							</div>
							<div class="mb-3" v-if="question.image !== null">
								<img :src="question.image" :alt="question.question" class="img-fluid" />
							</div>
							<div class="answer-form">
								<template v-for="answer in question.answers">
									<div :class="{'answer': 1, 'right': answer.is_right}">
										<i class="fa fa-check" v-if="answer.is_right"></i> {{ answer.answer }}
									</div>
								</template>
							</div>
						</div>
					</div>
					<div class="game-winners">
						<i class="fa fa-star"></i>
						<strong>A játék nyertesei</strong>
						<div v-if="winners.length">
							<div v-for="winner in winners">
								{{ winner.name }}, {{ winner.city }}
							</div>
						</div>
						<div v-else>
							Még nincsenek kisorsolva
						</div>
					</div>
				</template>
			</template>
		</div>
	</div>
</template>
<script>

import {formatDate} from "../functions";
import InputField from "../components/form/InputField.vue";
import FormWrapper from "../components/FormWrapper.vue";

export default {
	components: {FormWrapper, InputField},
	props: {
		id: Number,
		dataUrl: String,
		rulesUrl: String,
	},
	mounted() {
		this.load()
        if (document.grecaptcha === undefined) {
            let t = this
            window.recaptchaLoaded = () => {
                grecaptcha.enterprise.render("recaptcha_container", {
                    sitekey: "6Ld3s8QpAAAAAFpzAua35frS8E13_L9BLQwycf-m",
                    action: "verify",
                    callback: (token) => {
                        t.player.token = token
                    },
                });
            }

            let recaptchaScript = document.createElement("script");
            recaptchaScript.setAttribute(
                "src",
                "https://www.google.com/recaptcha/enterprise.js?onload=recaptchaLoaded"
            );
            recaptchaScript.setAttribute("async", "true");
            recaptchaScript.setAttribute("defer", "true");
            document.head.appendChild(recaptchaScript);
        }
	},
	computed: {
		canSubmitForm: {
			get() {
			}
		}
	},
	data(){
		return {
			loading: true,
			game: {},
			questions: [],
			winners: [],
			played: false,
			answers: {},
			canSubmitForm: false,
			message: '',
			player: {
				name: '',
				email: '',
				city: '',
				acceptRules: 0,
                token: 'x'
			}
		}
	},
	methods: {
		formatDate,
		load: function () {
			this.loading = true
			let that = this
			axios.get(this.dataUrl)
				.then(function (response) {
					that.processResponse(response)
				})
				.catch(function (error) {
				});
		},
		saveAnswers: function (answer){
			if (!this.canSubmitForm){
				return
			}
			this.loading = true
			let that = this
			this.message = ''
			axios.post(this.dataUrl, {answers: this.answers, player: this.player})
				.then(function (response) {
					that.processResponse(response)
				})
				.catch(function (error) {
				});

		},
		processResponse: function (response){
			let that = this
			this.loading = false
			this.game = response.data.game
			this.questions = response.data.questions
			this.played = response.data.played
			if (response.data.message !== undefined) {
				this.message = response.data.message
			}
			if (response.data.winners !== undefined) {
				this.winners = response.data.winners
			}

			_.forEach(this.questions, function (question){
				if (that.answers[question.id] === undefined) {
					that.answers[question.id] = null
				}
			})
		},
		updateCanSubmitForm: function (){
			this.canSubmitForm = true
			for (let i = 0; i < this.questions.length; i++){
				if (!this.answers[this.questions[i].id]){
					this.canSubmitForm = false
				}
			}
			if (!this.player.name.length || !this.player.email.length || !this.player.city.length || !this.player.acceptRules){
				this.canSubmitForm = false
			}
		}
	},
}
</script>

<style scoped>
</style>
