<template>
	<teleport to="body">
		<div :class="{'flash-wrapper': !inline, 'flash-wrapper-inline': inline, 'visible': visible}">
			<template v-if="flash !== undefined">
				<template v-for="(item, key) in flash">
					<div v-if="hidden.indexOf(key) < 0" :class="'alert alert-' + item.level" @click.prevent="clicked(key)">
						{{ item.message }}
					</div>
				</template>
			</template>
		</div>
	</teleport>
</template>

<script>
export default {
	name: "FlashDisplay",
	props: {
		flash: Array,
		inline: Boolean
	},
	data() {
		return {
			visible: false,
			hideTimeout: null,
			flashDisplayed: false,
			hidden: []
		}
	},
	watch: {
		flash(newValue, oldValue) {
			this.flashDisplayed = false
			this.update()
		}
	},
	updated() {
	},
	methods: {
		update: function(){
			if (!this.visible && this.flash.length && !this.flashDisplayed){
				let that = this
				this.flashDisplayed = true
				setTimeout(function(){
					that.visible = true

				}, 50)

				if (this.hideTimeout){
					clearTimeout(this.hideTimeout)
				}
				that.hideTimeout = setTimeout(function(){
					that.visible = false
				}, 10000)
			}
			if (this.visible && !this.flash.length){
				this.visible = false
			}
			this.hidden = []
		},
		clicked: function(index){
			if (!this.inline){
				this.visible = false
			} else {
				this.hidden.push(index)
			}

		}
	}
}
</script>

<style scoped>
.flash-wrapper {
	position: fixed;
	left: 50%;
	top: 0;
	transform: translate(-50%, -100%);
	z-index: 500000;
	padding: 30px;
	transition: all .6s ease;
}
.flash-wrapper.visible {
	opacity: 1;
	transform: translate(-50%, 0);
}
.flash-wrapper .alert,
.flash-wrapper-inline .alert {
	cursor: pointer;
}
</style>