<template>
	<button type="button" class="btn" @click.prevent="open = !open">
		<i class="fas fa-search" v-if="!open"></i>
		<i class="fas fa-times" v-else></i>
	</button>
	<teleport to="body" v-if="open">
		<div class="post-search-field--mobile">
			<post-search-field></post-search-field>
		</div>
	</teleport>
</template>
<script>
import PostSearchField from "./PostSearchField.vue";
export default {
	props: {
	},
	components: {PostSearchField},
	mounted() {
	},
	computed: {
	},
	data(){
		return {
			open: false
		}
	},
	methods: {
	},
}
</script>

<style scoped>
.btn {
	color: #fff;
	font-size: 22px;
	padding: 10px 14px;
	line-height: 30px;
	border: 0 none !important;
}
.btn:hover, .btn:active, .btn:focus {
	color: var(--bs-gray-800)
}
</style>
