<template>
	<div :class="{'recommended-posts': 1, 'loading': loading}" :id="'recommendedPosts_' + id" v-if="loading || items.length" :data-posts="JSON.stringify(items)">
		<h3><i class="fa fa-plus"></i> {{ title }}</h3>
		<div class="recommended-posts-list">
			<div class="row">
				<div class="col-lg-3 col-xs-6" v-for="post in items">
					<article>
						<div class="img">
							<img :src="post.thumbnail_square" class="img-fluid" :alt="post.title">
						</div>
						<h2>{{ post.title }}</h2>
						<a :href="post.url"></a>
					</article>
				</div>
			</div>
			<button type="button" class="reload" @click.prevent="loadRecommended"><span><i class="fa fa-redo-alt"></i> Kérek újat</span></button>
		</div>
	</div>
</template>
<script>

export default {
	props: {
		id: Number
	},
	mounted() {
		this.loadRecommended()
	},
	computed: {
	},
	data(){
		return {
			loading: true,
			items: [],
			title: ''
		}
	},
	methods: {
		loadRecommended: function () {
			this.loading = true
			let that = this
			axios.get(location.pathname + '?load=recommended')
					.then(function (response) {
						that.loading = false
						that.items = response.data.posts
						that.title = response.data.title
					})
					.catch(function (error) {
					});
		}
	},
}
</script>

<style scoped>
</style>
