<template>
	<div class="post-slider">
		<carousel :items-to-show="1" :wrap-around="true" ref="slider" aria-label="Cikkek" :autoplay="5000" @init="inited = true">
			<slide v-for="(post, postIndex) in posts" :key="'slide_' + postIndex">
				<div class="post-slider-item">
					<img :src="post.thumbnail" :alt="post.title" class="img-fluid" />
					<div class="post-slider-item--content">
						<h2>{{ post.title }}</h2>
					</div>
					<a :href="post.url"></a>
				</div>
			</slide>
		</carousel>

		<template v-if="inited">
			<div class="post-slider-indicator" v-if="$data.$screenSize.breakPoint === 'xs' || $data.$screenSize.breakPoint === 'sm' || $data.$screenSize.breakPoint === 'm'">{{ indicatorText }}</div>
			<button class="post-slider-navigation post-slider-navigation-prev" type="button" @click.prevent="$refs.slider.prev()" title="Előző cikk"><i class="far fa-angle-left"></i></button>
			<button class="post-slider-navigation post-slider-navigation-next" type="button" @click.prevent="$refs.slider.next()" title="Következő cikk"><i class="far fa-angle-right"></i></button>
		</template>
	</div>
</template>
<script>

import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
export default {
	components: {
		Carousel,
		Slide,
		Pagination,
		Navigation,
	},
	props: {
		posts: Array
	},
	mounted() {
	},
	computed: {
		indicatorText: {
			get(){
				if (this.inited !== undefined) {
					let max = this.$refs.slider.data.slidesCount.value
					return Math.min(max, this.$refs.slider.data.currentSlide.value + 1) + ' / ' + max
				}

				return ''
			}
		}
	},
	data(){
		return {
			currentSlide: 1,
			inited: false
		}
	},
	methods: {
	},
}
</script>

<style scoped>
</style>
