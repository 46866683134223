<template>
	<div class="post-image">
		<a :href="file.download_url" class="lightbox" :title="file.description ? file.description : postTitle" @click.prevent="$emit('openLightbox')">
			<img :src="getThumbnailUrl(file)" class="img-fluid"
					 :alt="file.description ? file.description : postTitle"/>
			<div class="image-count" v-if="displayDetails && displayImageCount">
				<i class="far fa-expand-wide"></i> <strong>{{ imageCount }}</strong>
			</div>
		</a>
		<div class="caption" v-if="displayDetails && (file.description || file.photographer)">
			<p class="m-0" v-if="file.description">{{ file.description }}</p>
			<p class="m-0" v-if="file.photographer"><small>(Fotó: {{ file.photographer }})</small></p>
		</div>
	</div>

</template>
<script>
export default {
	emits: ['openLightbox'],
	props: {
		file: Object,
		postTitle: String,
		view: String,
		displayDetails: Boolean,
		displayImageCount: Boolean,
		imageCount: Number
	},
	data() {
		return {}
	},
	methods: {
		getThumbnailUrl: function (file) {
			if (file.extension === 'gif'){
				return '/files/' + file.hash.substring(0, 2) + '/' + file.hash.substring(2, 4) + '/' + file.hash + '.' + file.extension
			}
			if (this.view !== '1col'){
				return '/files/' + file.hash.substring(0, 2) + '/' + file.hash.substring(2, 4) + '/' + file.hash + '_200x200_c.' + file.extension
			} else {
				return '/files/' + file.hash.substring(0, 2) + '/' + file.hash.substring(2, 4) + '/' + file.hash + '_730x0_c.' + file.extension
			}

		}
	},
}
</script>

<style scoped>
</style>
