<template>
    <img src="/pics/loader.svg" class="img-fluid" v-if="!loaded" alt="Betöltés" />
    <div :class="{'image-loading': !loaded}">
	    <img :src="src" :alt="alt" class="img-fluid" @load="loaded = true" v-if="loading" />
    </div>
</template>
<script>
export default {
	props: {
		src: String,
		alt: String,
	},
	data() {
		return {
			loaded: false,
			loading: false
		}
	},
	methods: {
	},
}
</script>

<style scoped>
</style>
