<template>
	<div class="reading-progress"><div :style="'width: ' + progBarValue + '%'"></div></div>
</template>
<script>

export default {
	props: {
	},
	computed: {
		progBarMax: {
			get() {
				return this.clientHeight - window.innerHeight
			}
		}
	},
	mounted() {
		window.addEventListener("scroll", this.updateProgressValue);
		this.clientHeight = document.clientHeight;
	},
	data(){
		return {
			progBarValue: 0,
			clientHeight: 0
		}
	},
	methods: {
		updateProgressValue: function() {
			this.progBarValue = (document.body.scrollTop || document.documentElement.scrollTop) / (document.documentElement.scrollHeight - document.documentElement.clientHeight) * 100;
		}
	},
}
</script>

<style scoped>
</style>
