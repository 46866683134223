<template>
	<div class="theme-switch">
		<a href="#" class="btn btn-dark btn-sm" v-if="theme === 'light'" @click.prevent="setTheme('dark')"><i class="far fa-moon"></i> Sötét mód</a>
		<a href="#" class="btn btn-light btn-sm" v-if="theme === 'dark'" @click.prevent="setTheme('light')"><i class="far fa-sun"></i> Világos mód</a>
	</div>
</template>
<script>

export default {
	props: {
		id: Number,
		dataUrl: String
	},
	mounted() {
		if (!this.$cookies.get('theme')) {
			this.setTheme(window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light")
		} else {
			this.setTheme(this.$cookies.get('theme'))
		}
	},
	computed: {
	},
	data(){
		return {
			theme: 'light'
		}
	},
	methods: {
		setTheme: function (theme){
			this.theme = theme

			this.$cookies.set('theme', this.theme, -1, '/')
			document.querySelector("html").setAttribute("data-bs-theme", this.theme)
		}
	},
}
</script>

<style scoped>
</style>
